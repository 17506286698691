import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

export const appRoutes: Route[] = [
    {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full',
    },
    {
        path: '',
        loadComponent: () => import('./core/auth0/login/login.component').then(mod => mod.LoginComponent),
    },
    {
        path: 'logout',
        loadComponent: () => import('./core/auth0/logout/logout.component').then(mod => mod.LogoutComponent),
    },
    {
        path: 'auth-callback',
        loadComponent: () =>
            import('./core/auth0/auth-callback/auth-callback.component').then(mod => mod.Auth0CallbackComponent),
    },
    {
        path: 'users',
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/users/users.component').then(m => m.UsersComponent),
    },
    {
        path: 'invitations',
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/invitations/invitations.component').then(m => m.InvitationsComponent),
    },
    {
        path: 'organizations',
        canActivate: [AuthGuard],
        loadComponent: () =>
            import('./pages/organizations/organizations.component').then(m => m.OrganizationsComponent),
    },
    {
        path: 'plans',
        canActivate: [AuthGuard],
        loadComponent: () => import('./pages/plans/plans.component').then(m => m.PlansComponent),
    },
    {
        path: '404',
        loadComponent: () =>
            import('./pages/error-pages/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
    },
    {
        path: '**',
        redirectTo: '404',
    },
];
