import { Injectable } from '@angular/core';
import { Subject, distinctUntilChanged, debounceTime, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SpinnerService {
    private $showSpinner = new Subject<boolean>();
    constructor() {}

    spinnerStatus(): Observable<boolean> {
        return this.$showSpinner.pipe(debounceTime(100), distinctUntilChanged());
    }

    showSpinner() {
        this.$showSpinner.next(true);
    }

    hideSpinner() {
        this.$showSpinner.next(false);
    }
}
