<nav [attr.role]="'navigation'" class="sidebar" [class.open]="opened && !pushMode" (mouseenter)="mouseEnterSidebar()" (mouseleave)="mouseLeaveSidebar()">
    <button
        *ngIf="showToggleButton"
        mat-icon-button
        type="button"
        (click)="changeSidenavMode()"
        class="toggle-button u-mt-16"
        [class.closed]="!opened"
        aria-label="Open Sidebar"
        (mouseenter)="mouseEnterSidebarToggle()"
    >
        <mat-icon>{{ pushMode ? 'chevron_left' : 'chevron_right' }}</mat-icon>
    </button>
    <div class="sidebar--content" role="menu">
        <div class="u-mt-48">
            <backoffice-app-fe-sidebar-item [opened]="opened" [link]="'users'" aria-hidden="false" aria-label="Users" role="menuitem" icon="group">
                Users
            </backoffice-app-fe-sidebar-item>
            <backoffice-app-fe-sidebar-item
                [opened]="opened"
                [link]="'invitations'"
                aria-hidden="false"
                aria-label="Invitations"
                role="menuitem"
                icon="forward_to_inbox"
            >
                Invitations
            </backoffice-app-fe-sidebar-item>
            <backoffice-app-fe-sidebar-item
                [opened]="opened"
                [link]="'organizations'"
                aria-hidden="false"
                aria-label="Organizations"
                role="menuitem"
                icon="corporate_fare"
            >
                Organizations
            </backoffice-app-fe-sidebar-item>
            <backoffice-app-fe-sidebar-item [opened]="opened" [link]="'plans'" aria-hidden="false" aria-label="Plans" role="menuitem" icon="checklist">
                Plans
            </backoffice-app-fe-sidebar-item>
        </div>
        <div>
            <backoffice-app-fe-sidebar-item [opened]="opened" [link]="'logout'" aria-hidden="false" aria-label="Logout" role="menuitem" icon="logout">
                Logout
            </backoffice-app-fe-sidebar-item>
        </div>
    </div>
</nav>
