import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { importProvidersFrom } from '@angular/core';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { appRoutes } from './app/app.routes';
import { environment } from './environments/environment';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
        provideHttpClient(withInterceptorsFromDi()),
        importProvidersFrom(
            BrowserAnimationsModule,
            MatDialogModule,
            MatNativeDateModule,
            MatSnackBarModule,
            AuthModule.forRoot({
                ...environment.auth0,
            })
        ),
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'en-CH' },
    ],
};
