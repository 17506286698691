import { RouterModule } from '@angular/router';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderComponent } from './core/header/header.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { SpinnerComponent } from './shared/ui/spinner/spinner.component';

@Component({
    standalone: true,
    imports: [RouterModule, HeaderComponent, SidebarComponent, SpinnerComponent],
    selector: 'backoffice-app-fe-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    title = 'backoffice-app-fe';
}
