import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'backoffice-app-fe-sidebar-item',
    standalone: true,
    imports: [CommonModule, RouterModule, MatIconModule],
    templateUrl: './sidebar-item.component.html',
    styleUrls: ['./sidebar-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarItemComponent {
    @Input() link!: string | any[];
    @Input() icon: string | undefined;
    @Input() opened: boolean | null = true;
}
