import { BackofficeEnvironment } from './environment.model';

const api = 'https://eu-north-1-api.dev.sympheny.com';
const backofficeApiBase = `${api}/backoffice`;

export const environment: BackofficeEnvironment = {
    production: false,
    backofficeApi: `${backofficeApiBase}/mgmt`,
    auth0: {
        domain: 'login.dev.sympheny.com',
        clientId: 'b79blsjBm0eo2xHYKRia0H07o6bc5gro',
        authorizationParams: {
            audience: backofficeApiBase,
            allowedList: [backofficeApiBase],
            redirect_uri: `${window.location.origin}/auth-callback`,
            errorPath: '/auth-callback',
        },
        httpInterceptor: {
            allowedList: [
                {
                    uri: `${backofficeApiBase}*`,
                    tokenOptions: {
                        authorizationParams: {
                            audience: backofficeApiBase,
                        },
                    },
                },
            ],
        },
    },
};
