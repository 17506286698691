import { Component, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'backoffice-app-fe-sidebar',
    standalone: true,
    imports: [CommonModule, SidebarItemComponent, MatIconModule],
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
    @HostBinding('class.backoffice--sidebar-open') pushMode = true;
    opened = true;
    showToggleButton = true;
    timeOutTransition: any;

    constructor(private readonly cd: ChangeDetectorRef) {}

    mouseEnterSidebar(): void {
        this.timeOutTransition = setTimeout(() => {
            this.opened = true;
            this.showToggleButton = this.pushMode;
            this.cd.markForCheck();
        }, 200);
    }

    mouseLeaveSidebar(): void {
        clearTimeout(this.timeOutTransition);
        this.opened = this.pushMode ? this.opened : false;
        this.showToggleButton = !this.opened;
    }
    mouseEnterSidebarToggle(): void {
        clearTimeout(this.timeOutTransition);
    }

    changeSidenavMode(): void {
        this.pushMode = !this.pushMode;
        this.opened = !this.opened;
        this.showToggleButton = this.pushMode && this.opened ? false : true;
    }
}
